// Add jQuery
var jquery = require('jquery');
window.$ = window.jQuery = jquery;
require('../node_modules/jquery/dist/jquery.slim');

$(function () {

    // True if element in viewport
    $.fn.is_on_screen = function () {
        var win = $(window);
        var viewport = {
            top: win.scrollTop(),
            left: win.scrollLeft()
        };
        viewport.bottom = viewport.top + win.height() + 110;
        var bounds = this.offset();
        bounds.bottom = bounds.top + this.outerHeight();
        return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    };

    // Parallax for background and element
    function has_parallax() {
        var scrolled = $(window).scrollTop();
        $('.is-parallax-bg').each(function () {
            if ($(this).closest('section').is_on_screen()) {
                var firstTop = $(this).closest('section').offset().top;
                $(this).css('transform', 'translateY(' + -(firstTop - scrolled) * 0.2 + 'px)');
            }
        });
        $('.is-parallax-element').each(function () {
            if ($(this).closest('section').is_on_screen()) {
                var firstTop = $(this).closest('section').offset().top;
                $(this).css('transform', 'translateY(' + -(firstTop - scrolled) * 0.3 + 'px)');
            }
        });
        $('.is-parallax-element-fs').each(function () {
            if ($(this).closest('section').is_on_screen()) {
                var firstTop = $(this).closest('section').offset().top;
                $(this).css('transform', 'perspective(500px) rotateX(' + -(firstTop - scrolled) * 0.05 + 'deg) translateY(' + -(firstTop - scrolled) * .2 + 'px)');
            }
        });
    }

    // Run shit on scroll
    $(window).scroll(function () {
        has_parallax();
    });

});

// Fix fullheight on mobile (accounting for menu's and UI)
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);